import { HierarchicalTeaseEntry } from "frontend/contentful/schema/blocks";
import { createContentfulComponent } from "frontend/contentful/lib/create-contentful-component";
import { ArticleTeaseProps } from "design-system/components/primitives/article-tease/article-tease";
import { RenderContentfulRichText } from "../utils/render-contentful-rich-text";
import { useSearch } from "frontend/hooks/use-search";
import { TeaseStyle } from "design-system/types/types";
import { HierarchicalTease } from "design-system/components/blocks/hierarchical-tease/hierarchical-tease";
import { createUseSearchProps } from "../utils/use-search.props";
import { createSearchArticleTease } from "frontend/contentful/search/search-article-tease";
import { createCtaLinkProps } from "../primitives/cta-link.props";
import { LegacySearchHit, ModernSearchHit } from "frontend/types";
import { CreateEditAttributesFn } from "frontend/contentful/lib/edit-attributes-factory";

const CuratedContentfulHierarchicalTease = ({
  entry,
  createEditAttributes,
}: {
  entry: HierarchicalTeaseEntry;
  createEditAttributes: CreateEditAttributesFn;
}) => {
  const ids = entry.fields.teasesCurated || [];
  const articles: Array<ArticleTeaseProps> = [];

  const { data } = useSearch<ModernSearchHit>({
    index: "modern",
    query: ids.map((id) => `id:${id}`).join(" OR "),
    size: entry.fields.teasesCurated?.length || 25,
  });

  /**
   * Sorting hits based on the order of IDs.
   */
  const sortedHits = data?.hits.toSorted((a, b) => {
    return ids.indexOf(a.id) - ids.indexOf(b.id);
  });

  if (!sortedHits || sortedHits.length === 0) return null;

  let counter = 0;
  const allTitles: Array<string> = [];

  /**  Only show 4 articles and filter out duplicates
   *   This is a temporary solution to filter out duplicates, as I had few issues with duplicate results when picking similar articles
   */
  sortedHits.forEach((item) => {
    if (counter < 4) {
      const props = createSearchArticleTease(item);
      const title = props?.title?.toString();
      if (props && title && !allTitles.includes(title)) {
        articles.push(props);
        allTitles.push(title);
        counter++;
      }
    }
  });

  return (
    <HierarchicalTease
      articles={articles}
      feedTeaseStyle={entry.fields.feedTeaseStyle as TeaseStyle}
      header={{
        componentHeader: {
          isSmall: false,
          title: entry.fields.title,
          editAttributes: {
            title: createEditAttributes({ entry, fieldId: "title" }),
          },
        },
      }}
      editAttributes={{
        query: createEditAttributes({
          entry: entry.fields.query,
          fieldId: "query",
        }),
      }}
    />
  );
};

const QueryContentfulHierarchicalTease = ({
  entry,
  createEditAttributes,
}: {
  entry: HierarchicalTeaseEntry;
  createEditAttributes: CreateEditAttributesFn;
}) => {
  const articles: Array<ArticleTeaseProps> = [];

  const searchProps = createUseSearchProps(entry.fields.query);
  if (searchProps) searchProps.size = 4 + 2;
  let counter = 0;

  const search = useSearch<LegacySearchHit>(searchProps);

  if (Array.isArray(search.data?.hits)) {
    const allTitles: Array<string> = [];
    search.data?.hits.map(async (data) => {
      if (counter < 4) {
        const props = createSearchArticleTease(data);
        const title = props?.title?.toString();
        if (props && title && !allTitles.includes(title)) {
          articles.push(props);
          allTitles.push(title);
          counter++;
        }
      }
    });
  }

  if (
    entry.fields.headerSize === "standard" ||
    entry.fields.headerSize === undefined
  ) {
    return (
      <HierarchicalTease
        articles={articles}
        feedTeaseStyle={entry.fields.feedTeaseStyle as TeaseStyle}
        header={{
          componentHeader: {
            isSmall: false,
            title: entry.fields.title,
            editAttributes: {
              title: createEditAttributes({ entry, fieldId: "title" }),
            },
          },
        }}
        editAttributes={{
          query: createEditAttributes({
            entry: entry.fields.query,
            fieldId: "query",
          }),
        }}
      />
    );
  }

  return (
    <HierarchicalTease
      articles={articles}
      feedTeaseStyle={entry.fields.feedTeaseStyle as TeaseStyle}
      header={{
        title: entry.fields.title,
        overline: entry.fields.overline,
        cta: createCtaLinkProps(entry.fields.link),
        subheading: (
          <RenderContentfulRichText document={entry.fields.description} />
        ),
        editAttributes: {
          title: createEditAttributes({ entry, fieldId: "title" }),
          overline: createEditAttributes({ entry, fieldId: "overline" }),
          subheading: createEditAttributes({
            entry,
            fieldId: "description",
          }),
        },
      }}
      editAttributes={{
        query: createEditAttributes({
          entry: entry.fields.query,
          fieldId: "query",
        }),
      }}
    />
  );
};

export const ContentfulHierarchicalTease =
  createContentfulComponent<HierarchicalTeaseEntry>(
    ({ entry, createEditAttributes }) => {
      if (entry.fields.teasesCurated) {
        return (
          <CuratedContentfulHierarchicalTease
            entry={entry}
            createEditAttributes={createEditAttributes}
          />
        );
      }
      if (entry.fields.query) {
        return (
          <QueryContentfulHierarchicalTease
            entry={entry}
            createEditAttributes={createEditAttributes}
          />
        );
      }

      return null;
    },
  );
